import React, { ReactElement, useCallback, useEffect, useState } from "react";
import isEqual from "react-fast-compare";

import HighlightedNavigationItems from "components/HighlightedNavigationItems/HighlightedNavigationItems";
import { convertToValidID } from "utils/convertToValidID";

import {
    Wrapper,
    NavItems,
    NavItem,
    LinkWrapper,
    Expand,
    InnerNav,
    NavItemsWrapper,
    LinkLvl2,
    LinkLvl3,
} from "./NavigationMegaItems.styled";
import NavigationMegaItemsProps, {
    NavigationMegaItemsLvl2Props,
    NavigationMegaItemsLvl3Props,
} from "./NavigationMegaItemsProps";

const NavigationMegaItemsLvl3 = React.memo(
    ({
        item,
        innerSubNavId,
        isActiveSubNav,
        navigateHandler,
        navId,
    }: NavigationMegaItemsLvl3Props): ReactElement => {
        const handleNavigate = useCallback(
            (_) => {
                if (navigateHandler) {
                    navigateHandler(
                        3, //level
                        item.displayName,
                    );
                }
            },
            [item.displayName, navigateHandler],
        );
        return (
            <NavItem key={innerSubNavId}>
                <LinkLvl3
                    id={`${innerSubNavId}-header`}
                    to={item.url}
                    exact
                    hoverSpan={true}
                    isNavigation={true}
                    title={item.displayName}
                    tabIndex={isActiveSubNav(navId) ? 0 : -1}
                    onClick={handleNavigate}
                >
                    <span>{item.displayName}</span>
                </LinkLvl3>
            </NavItem>
        );
    },
);
NavigationMegaItemsLvl3.displayName = "NavigationMegaItemsLvl3";

const NavigationMegaItemsLvl2 = React.memo(
    ({
        item,
        hasChildren,
        isActiveSubNav,
        navigateHandler,
        toggleSubNav,
        onKeyDown,
        navId,
        subNavId,
        l18n,
    }: NavigationMegaItemsLvl2Props): ReactElement => {
        const handleClick = useCallback(
            () => toggleSubNav(navId),
            [navId, toggleSubNav],
        );

        const handleKeyPress = useCallback(
            (event: React.KeyboardEvent<HTMLButtonElement>) =>
                onKeyDown(event, navId),
            [onKeyDown, navId],
        );

        const handleNavigate = useCallback(() => {
            if (navigateHandler) navigateHandler(2, item.displayName);
        }, [item.displayName, navigateHandler]);

        const expandButton = (
            <Expand
                variant="outline"
                size="small"
                actionTheme="black"
                a11y={true}
                onClick={handleClick}
                onKeyDown={handleKeyPress}
                aria-controls={`${subNavId}-children`}
                aria-expanded={isActiveSubNav(navId)}
                aria-label={`${l18n?.expand ?? "expand"} ${item.displayName}`}
                icon={isActiveSubNav(navId) ? "arrowUp28" : "arrowDown28"}
            >
            </Expand>
        );

        return (
            <NavItem key={subNavId} $hasChildren={hasChildren}>
                <LinkWrapper $open={isActiveSubNav(navId)}>
                    <LinkLvl2
                        id={`${subNavId}-header`}
                        to={item.url}
                        exact
                        isNavigation={true}
                        hoverSpan={true}
                        title={item.displayName}
                        onClick={handleNavigate}
                    >
                        <span>{item.displayName}</span>
                    </LinkLvl2>
                    {hasChildren && expandButton}
                </LinkWrapper>
                {hasChildren && (
                    <>
                        <InnerNav
                            id={`${subNavId}-children`}
                            aria-expanded={
                                isActiveSubNav(navId) ? "true" : "false"
                            }
                            aria-labelledby={`${subNavId}-header`}
                            $open={isActiveSubNav(navId)}
                        >
                            {item.items?.map((item, index) => {
                                const innerSubNavId = convertToValidID(
                                    `${subNavId}-${item.displayName || navId}`,
                                );
                                return (
                                    item?.url && (
                                        <NavigationMegaItemsLvl3
                                            key={`NavLvl3-${index}`}
                                            item={item}
                                            innerSubNavId={innerSubNavId}
                                            navId={navId}
                                            isActiveSubNav={isActiveSubNav}
                                        />
                                    )
                                );
                            })}
                        </InnerNav>
                    </>
                )}
            </NavItem>
        );
    },
);
NavigationMegaItemsLvl2.displayName = "NavigationMegaItemsLvl2";

const NavigationMegaItems = ({
    id,
    items,
    open,
    highlightedNavigation,
    l18n,
    navigateHandler,
}: NavigationMegaItemsProps): ReactElement => {
    const [activeSubNav, setIsActiveSubNav] = useState<string[]>();
    const highlightedNavigationItems = highlightedNavigation?.items || [];

    useEffect(() => {
        if (!open) setIsActiveSubNav([]);
    }, [open]);

    const toggleSubNav = useCallback(
        (nav: string): void => {
            if (!activeSubNav || activeSubNav.length === 0) {
                setIsActiveSubNav([nav]);
            } else if (activeSubNav.includes(nav)) {
                setIsActiveSubNav(activeSubNav.filter((item) => item !== nav));
            } else {
                const newState = activeSubNav.slice();
                newState.push(nav);
                setIsActiveSubNav(newState);
            }
        },
        [activeSubNav],
    );

    const handleKeyDown = useCallback(
        (event: React.KeyboardEvent<HTMLButtonElement>, nav: string): void => {
            if (event?.key === "Enter") {
                event.preventDefault();
                toggleSubNav(nav);
            }
        },
        [toggleSubNav],
    );

    const isActiveSubNav = useCallback(
        (nav: string): boolean => !!activeSubNav && activeSubNav.includes(nav),
        [activeSubNav],
    );

    const hasHighlightedMenu = highlightedNavigationItems.length > 0;
    const numberOfColumns = hasHighlightedMenu ? 2 : 3;
    const numberPerColumn = items ? Math.ceil(items.length / numberOfColumns) : 1; // Equally distribute the items over three columns

    const itemsPerColumn = [];
    if (items)
        for (let i = 0; i < items.length; i += numberPerColumn)
            itemsPerColumn.push(items.slice(i, i + numberPerColumn));
    return (
        <Wrapper id={id}>
            <NavItemsWrapper $itemsPerColumn={numberPerColumn}>
                {itemsPerColumn?.map((items, colIndex) => {
                    return (
                        <NavItems key={`${id}-${colIndex}`} aria-labelledby={`${id}-heading`}>
                            {items?.map((item, index) => {
                                const navId = `${id}-${colIndex}-${index}`;
                                const subNavId = convertToValidID(
                                    `${id}-${
                                        item.displayName ||
                                        `${colIndex}-${index}`
                                    }`,
                                );
                                const hasChildren =
                                    item.items && item.items.length > 0;

                                return (
                                    item?.url && (
                                        <NavigationMegaItemsLvl2
                                            key={`NavLvl2-${index}`}
                                            hasChildren={hasChildren}
                                            item={item}
                                            isActiveSubNav={isActiveSubNav}
                                            toggleSubNav={toggleSubNav}
                                            navigateHandler={navigateHandler}
                                            onKeyDown={handleKeyDown}
                                            navId={navId}
                                            subNavId={subNavId}
                                            l18n={l18n}
                                        />
                                    )
                                );
                            })}
                        </NavItems>
                    );
                })}
                {hasHighlightedMenu && (
                    <HighlightedNavigationItems highlightedNavigation={highlightedNavigation} parentNavId={id} />
                )}
            </NavItemsWrapper>
        </Wrapper>
    );
};

export default React.memo(NavigationMegaItems, isEqual);
