import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import React, { ReactElement } from "react";
import isEqual from "react-fast-compare";

import Icon from "components/Icon/Icon";

import {
    Heading,
    NavShortcuts,
    NavShortcutsItem,
    NavShortcutsLink,
    Wrapper,
} from "./HighlightedNavigationItems.styled";
import HighlightedNavigationItemsProps from "./HighlightedNavigationItemsProps";

const HighlightedNavigationItems = ({
    highlightedNavigation,
    parentNavId,
}: HighlightedNavigationItemsProps): ReactElement => {
    const sendDataToGTM = useGTMDispatch();
    const highlightedNavigationItems = highlightedNavigation?.items || [];
    const highlightedNavigationHeading = highlightedNavigation?.heading;

    const handleClick = (displayName?: string): void => {
        sendDataToGTM({
            event: "highlight_click",
            click_text: displayName,
        });
    };

    return (
        <Wrapper>
            {highlightedNavigationHeading && (
                <Heading id={`${parentNavId}-highlight-heading`}>
                    {highlightedNavigationHeading}
                </Heading>
            )}
            <NavShortcuts
                aria-labelledby={
                    highlightedNavigationHeading &&
                    `${parentNavId}-highlight-heading`
                }
                aria-describedby={`${parentNavId}-heading`}
            >
                {highlightedNavigationItems.map((item, index) => {
                    const icon =
                        typeof item.properties?.icon === "string"
                            ? item.properties.icon
                            : undefined;

                    return (
                        <NavShortcutsItem key={index}>
                            <NavShortcutsLink
                                to={item.url}
                                exact
                                isNavigation={true}
                                hoverSpan={true}
                                title={item.displayName}
                                onClick={() => handleClick(item.displayName)}
                            >
                                {icon && <Icon icon={icon} />}
                                <div>
                                    <span>{item.displayName}</span>
                                </div>
                            </NavShortcutsLink>
                        </NavShortcutsItem>
                    );
                })}
            </NavShortcuts>
        </Wrapper>
    );
};

export default React.memo(HighlightedNavigationItems, isEqual);
